.ds-carousel {
    width: 100%;

    .content-block {
        transition: border-color var(--ds-main-transition);

        &:hover {
            box-shadow: none;
            border-color: #eaeaea;
            
            .ds-carousel-title {
                color: var(--ds-links-color);
            }
        }
    }

    &-title {
        font-size: 0.625rem;

        @media (min-width: $break-md) {
            font-size: 0.75rem;
        }
        
        &:hover {
            text-decoration: underline;
        }
    }

    margin-bottom: 2rem;
}